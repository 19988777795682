/** @jsx jsx */
import { jsx, Text, Flex, Button } from 'theme-ui'
import Meta from './meta'
import { Link } from 'gatsby'
import ShopIcon from '../../images/shop.png'
import { responsive } from '../../utils/helpers'
import { onClickGtag } from '../../utils/gtag'

const ImageOverlay = props => {
  const imageOverlaySX = {
    width: '100%',
    height: '100%',
    top: '0px',
    padding: 4,
    flexDirection: 'column',
    justifyContent: 'flex-end',
    position: 'absolute',
    bottom: '0px',
    left: '0px',

    '&:before': {
      content: '""',
      position: 'absolute',
      bottom: '0px',
      left: '0px',
      width: '100%',
      height: '100%',
      background:
        'linear-gradient(to bottom, rgba(153,153,153,0) 0%,rgba(0,0,0,0.6) 100%)',
    },
  }

  const textFlourishSX =
    props.category && props.category.flourish
      ? {
          paddingTop: 2,

          '&:before': {
            content: '""',
            display: 'block',
            width: '64px',
            height: '3px',
            backgroundColor: 'primary',
            position: 'absolute',
            left: -4,
            top: '0px',
          },
        }
      : {}

  const textOverlaySX = {
    position: 'relative',
  }

  const buttonSX = {
    position: 'relative',
    alignSelf: 'flex-start',
    marginTop: 2,
  }

  const shopIconSX = {
    width: '120px',
    height: 'auto',
    position: 'relative',
    mb: '1',
  }

  return (
    <Flex sx={imageOverlaySX}>
      {props.shop && <img src={ShopIcon} sx={shopIconSX} alt="FGR Shop" />}
      {props.category && (
        <Text
          variant="cardCategory"
          sx={{ ...textOverlaySX, ...textFlourishSX, color: 'primary' }}
        >
          {props.category.name}
        </Text>
      )}
      {props.title && (
        <Text
          variant="cardTitle"
          sx={{
            ...textOverlaySX,
            color: 'white',
            fontSize: responsive(8, 9, 8),
          }}
        >
          {props.title}
        </Text>
      )}
      {props.meta && (
        <Meta date={props.meta.date} sx={{ marginTop: 2 }} bright />
      )}
      {props.button && (
        <Button
          sx={buttonSX}
          as={Link}
          to={props.button.url}
          onClick={() =>
            props.clickTracking.category &&
            onClickGtag(
              'click',
              props.clickTracking.category,
              props.clickTracking.label
            )
          }
        >
          {props.button.label}
        </Button>
      )}
    </Flex>
  )
}

export default ImageOverlay
