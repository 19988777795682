/** @jsx jsx */
import { jsx, Box, AspectRatio } from 'theme-ui'
import ImageOverlay from './imageOverlay'
import { responsive } from '../../utils/helpers'
import { GatsbyImage } from "gatsby-plugin-image";

const Image = props => {
  const containerSX = {
    position: props.aspectRatio ? 'absolute' : 'relative',
    height: props.aspectRatio
      ? 'auto'
      : props.height
      ? props.height
      : props.fullHeight
      ? '100%'
      : '0px',
    width: props.aspectRatio
      ? 'auto'
      : props.thin
      ? '50%'
      : props.wide
      ? '100%'
      : 'auto',
    paddingBottom: props.aspectRatio
      ? 'auto'
      : props.fullHeight || props.height
      ? '0px'
      : props.short
      ? '72%'
      : responsive('90%', '110%'),
    flexShrink: '0',
  }

  const imageSX = {
    width: '100% !important',
    height: '100% !important',
    top: '0px',
    left: '0px',
    position: 'absolute !important',
    objectFit: 'cover',
  }

  const iconSX = {
    position: 'absolute',
    left: 4,
    top: 4,
  }

  if (!props.thumbnail && !props.gatsbyThumbnail) return null

  const Tag = props.aspectRatio ? AspectRatio : Box

  return (
    <Tag sx={containerSX} ratio={props.aspectRatio}>
      {props.gatsbyThumbnail && (
        <GatsbyImage image={props.gatsbyThumbnail} sx={imageSX} alt="" />
      )}
      {!props.gatsbyThumbnail && (
        <img src={props.thumbnail.url} sx={imageSX} alt="" />
      )}
      <Box sx={iconSX}>{props.icon}</Box>
      {props.overlay && (
        <ImageOverlay
          title={props.title}
          category={props.category}
          meta={props.meta}
          button={props.button}
          shop={props.shop}
          clickTracking={props.clickTracking}
        />
      )}
    </Tag>
  );
}

export default Image
