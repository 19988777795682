/** @jsx jsx */
import { jsx, Card, Flex } from 'theme-ui'
import Image from './image'
import { Link } from 'gatsby'

const ImageCard = props => {
  const wrapperSX = {
    flexDirection: props.featured ? 'row' : 'column',
    height: '100%',
  }

  const cardSX = {
    cursor: props.onClick ? 'pointer' : 'default',
    height: props.aspectRatio ? props.height : 'auto',
    padding: '0px',
    borderRadius: 8,
    boxShadow: '0 0 8px rgba(0, 0, 0, 0.125)',
    background: 'white',
    overflow: 'hidden',
  }

  const Tag = props.state && props.url ? Link : Card

  return (
    <Tag
      variant="slim"
      className={props.className}
      as={props.url ? Link : 'div'}
      to={props.url ? props.url : null}
      onClick={props.onClick}
      sx={cardSX}
      state={props.state}
    >
      <Flex sx={wrapperSX} as="figure">
        <Image
          title={props.title}
          category={props.category}
          thumbnail={props.thumbnail}
          gatsbyThumbnail={props.gatsbyThumbnail}
          icon={props.icon}
          overlay
          wide
          fullHeight={props.fullHeight}
          button={props.button}
          short={props.short ? props.short : props.featured}
          meta={props.meta}
          height={props.height}
          aspectRatio={props.aspectRatio}
          shop={props.shop}
          clickTracking={props.clickTracking}
        />
      </Flex>
    </Tag>
  )
}

export default ImageCard
