/** @jsx jsx */
import { jsx, Flex, useThemeUI, get, Text } from 'theme-ui';
import Share from '../../images/share.png';
import ShareWhite from '../../images/share-white.png';
import Circle from '../shapes/circle';

const moment = require('moment');

const Meta = (props) => {

  const { theme } = useThemeUI();

  const metaSX = {
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 'auto',
    position: 'relative',
  }

  const shareSX = {
    width: '24px',
    height: '26px'
  }

  const textSX = {
    color: props.bright ? 'white' : 'black'
  }

  return (
    <Flex {...props} sx={metaSX}>
      <time sx={textSX}><Circle sx={{marginRight: 1}} width={7} height={7} colour={get(theme, 'colors.primary')} />{moment(props.date).fromNow()}</time>
      <Text sx={textSX}><img sx={shareSX} src={props.bright ? ShareWhite : Share} alt='share' /></Text>
    </Flex>
  )
}

export default Meta