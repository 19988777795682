/** @jsx jsx */
import { jsx, Text, Card, Flex, Box, useThemeUI, get } from 'theme-ui';
import { Link } from 'gatsby'

const Partner = (props) => {

  const { theme } = useThemeUI()
  const themeButton = get(theme, 'buttons.primary')
  const buttonSX = {
    ...themeButton,
    backgroundColor: 'primary',
    textDecoration: 'none',
    display: 'inline-block',
  }

  const imageContainerSX = {
    height: props.variant === 'mid' ? '150px' : '290px',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 5,
    borderBottom: '1px solid',
    borderColor: 'background',

    'img': {
      maxWidth: '100%'
    }
  }

  const captionSX = {
    padding: 4,
  }

  const contentSX = {
    lineHeight: 'body',
    mb: 6,
  }

  const headingSX = {
    fontFamily: 'heading',
    fontSize: 8,
    textAlign: 'center',
    mb: 2
  }

  return (
    <Card variant="slim" as="figure">
      {props.image &&
        <Flex sx={imageContainerSX}>
          <img src={props.image} alt="" />
        </Flex>
      }
      <Box as="figcaption" sx={captionSX}>
        <Text sx={headingSX} as='h3'>{props.title}</Text>
        <Text mb={3} as="p" sx={contentSX}>{props.content}</Text>
        {props.button &&
          <Link to={props.button.url} sx={buttonSX}>{props.button.label}</Link>
        }
      </Box>
    </Card>
  )
}

export default Partner