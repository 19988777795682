/** @jsx jsx */
import { jsx, Text, Card, Flex } from 'theme-ui';
import React from 'react';
import Image from './image';
import Meta from './meta'
import { Link } from 'gatsby'

const CaptionCard = (props) => {
  
  const wrapperSX = {
    flexDirection: props.featured ? 'row' : 'column',
    height: '100%'
  }

  const paddingSX = {
    padding: 4
  }

  const cardSX = {
    textDecoration: 'none',
    color: 'inherit',
    display: 'block'
  }

  const captionSX = {
    flexDirection: 'column',
    height: props.featured ? 'auto' : '100%'
  }

  const contentSX = {
    fontSize: 2, 
    marginBottom: 9,
    lineHeight: 'body'
  }

  const textOverlaySX = {
    position: 'relative'
  }

  const Caption = () => {

    return (
      <Flex sx={{
        ...paddingSX, 
        ...captionSX,
      }}>
        {(props.featured || !props.thumbnail) &&
          <React.Fragment>
            {props.category &&
              <Text as="h4" variant='cardCategory' sx={textOverlaySX}>{props.category.name}</Text>
            }
            {props.title &&
              <Text as="h3" variant='cardTitle' sx={{...textOverlaySX, marginBottom: 2}}>{props.title}</Text>
            }
            {props.icon}
          </React.Fragment>
        }
        <Content />
        <Meta date={props.date} />
      </Flex>
    )
  }

  const Content = () => {
    return <Text sx={contentSX} dangerouslySetInnerHTML={{__html: props.excerpt}} />
  }

  return (
    <Card variant="slim" sx={cardSX} className={props.className} as={props.url ? Link : 'div'} to={props.url ? props.url : null} target={props.target ? props.target : '_self'}>
      <Flex sx={wrapperSX} as="figure">
        <Image 
          title={props.title} 
          category={props.category} 
          thumbnail={props.thumbnail} 
          gatsbyThumbnail={props.gatsbyThumbnail}
          short={props.featured} 
          thin={props.featured} 
          overlay={props.hideOverlay ? false : !props.featured} 
          icon={props.icon}
        />

        <Caption />
      </Flex>
    </Card>
  )
}

export default CaptionCard

